<script lang="ts">
  import { ArrowUpRight, Monitor } from "lucide-svelte";

  import { analytics } from "$lib/analytics";
  import { user } from "$lib/user";

  export let next: string = "/apps";

  $: isLoggedIn = $user?.logged_in ?? false;
</script>

{#if !isLoggedIn}
  <div
    class="flex flex-row items-center justify-between gap-x-4 text-lg md:text-sm"
  >
    <a
      href={"/login?next=" + encodeURIComponent(next)}
      class="flex items-center text-light-green hover:text-brand-green whitespace-nowrap"
      on:click
    >
      Log In
    </a>
    <a
      class="relative group text-brand-green rounded-full flex items-center gap-2.5 pl-3 overflow-hidden"
      href={"/signup?next=" + encodeURIComponent(next)}
      on:click={() => {
        analytics.track("Signup Button Clicked");
      }}
    >
      <!-- background transition bubble -->
      <span
        class="absolute inset-0 rounded-full bg-brand-green transform scale-x-0 origin-right transition-transform duration-150 ease-out group-hover:scale-x-100"
      ></span>

      <span
        class="relative z-10 text-brand-green transition-colors duration-150 ease-in-out group-hover:text-black whitespace-nowrap"
      >
        Sign Up
      </span>
      <span class="inline-block w-7 h-7 p-1.5 rounded-full bg-brand-green z-10"
        ><ArrowUpRight size={16} class="w-full h-full stroke-black" /></span
      >
    </a>
  </div>
{:else}
  <a
    class="-mx-3 md:ml-0 md:mr-2 inline-flex items-center gap-x-2 px-3 py-1 text-primary active:bg-primary/20 hover:bg-primary/20 rounded-md"
    href="/apps"
    on:click
  >
    <Monitor size={16} />
    <span class="text-lg md:text-sm">Dashboard</span>
  </a>
{/if}
